body {
  margin: 0;
  padding: 0;
  background-color: #f3f3f3;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#header{
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.9)),
    url(https://images.unsplash.com/photo-1569437061241-a848be43cc82?ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=750&amp;q=80);
	background-repeat: no-repeat;
	background-size: cover;
  background-position: center center;
  /* opacity: 0.5; */
  /* filter: blur(10px); */
  /* background-color: rgba(0,0,0,0.9); */
}


.input{
  border: solid 1px rgba(0,0,0,0.2);
  border-radius: 5px;
  width: 100%;
  /* z-index: 1000; */
}
.css-26l3qy-menu, .select{
  position: relative !important ; 
  width: auto;
}



#file-upload {
  display: none;
}
#upload-area {
  display: block;
  cursor: pointer;
  background-color: #ededed;
  padding: 24px;
  border: 1px dashed #aeaeae;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#upload-area:hover {
  background-color: #dedede;
}
#upload-area > div {
  padding: 8px;
}
#upload-area > div.label-line {
  padding: 8px 40px;
  position: relative;
}
#upload-area > div.label-line > span {
  font-size: 14px;
  text-transform: uppercase;
  color: #666;
}
#upload-area > div > button {
  cursor: pointer;
  border: none;
  font-size: 12px;
}
#upload-area > div > button.files-upload {
  padding: 5px 16px;
  border-radius: 3px;
  background-color: #3071A9;
  color: #ffffff;
}
#upload-area > div > button.files-clear {
  position: absolute;
  top: 50%;
  right: 8px;
  font-size: 0;
  height: 24px;
  width: 24px;
  margin-top: -13px;
  background: none;
  outline: none;
  border: none;
}
#upload-area > div > button.files-clear::before,
#upload-area > div > button.files-clear::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 1px;
  top: 12px;
  left: 4px;
  background-color: #343434;
}
#upload-area > div > button.files-clear::before {
  transform: rotate(45deg);
}
#upload-area > div > button.files-clear::after {
  transform: rotate(-45deg);
}
